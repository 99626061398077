import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@teamfoster/sdk/icon';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromStore from './store';
import { RouterModule } from '@angular/router';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { ImageModule } from '@teamfoster/sdk/image';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CoreModule } from '../core/core.module';
import { TipOverviewComponent } from './containers/tips-overview/tip-overview.component';
import { TipDetailComponent } from './containers/tip-detail/tip-detail.component';
import { TipRoutingModule } from './tip-routing.module';
import { TipCardComponent } from './components/tip-card/tip-card.component';

@NgModule({
  declarations: [TipOverviewComponent],
  exports: [TipDetailComponent],
  imports: [
    CommonModule,
    IconModule,
    ReactiveFormsModule,
    FormsModule,
    ImageModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    DictionaryNgrxModule,
    RouterModule,
    TextUtilityModule,
    TipRoutingModule,
    TipDetailComponent,
    CoreModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.effects),
    TipCardComponent,
  ],
})
export class TipModule {}

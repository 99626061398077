import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TipDetailComponent } from './containers/tip-detail/tip-detail.component';
import { TipOverviewComponent } from './containers/tips-overview/tip-overview.component';
import { TipGuard } from './guards';

const routes: Routes = [
  {
    path: ':id/:title',
    component: TipDetailComponent,
    canActivate: [TipGuard],
  },
  {
    path: '',
    component: TipOverviewComponent,
    canActivate: [TipGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TipRoutingModule {}

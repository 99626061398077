import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Tip } from '../../models';
import { IconModule } from '@teamfoster/sdk/icon';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { CommonModule } from '@angular/common';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { RouterModule } from '@angular/router';
import { ImageModule, Image } from '@teamfoster/sdk/image';
import { ButtonModule } from '@teamfoster/sdk/button';

@Component({
  selector: 'app-tip-card',
  standalone: true,
  imports: [IconModule, DictionaryNgrxModule, CommonModule, TextUtilityModule, RouterModule, ImageModule, ButtonModule],
  templateUrl: './tip-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TipCardComponent {
  @Input({ required: true }) tip!: Tip;

  get image(): Image {
    return this.tip.image?.url
      ? this.tip.image
      : { url: 'https://www.rova.nl/media/uploads/placeholder-image-groen.png', alt: 'Placeholder afbeelding ROVA print' };
  }
}

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromstore from '../../store';

@Component({
  selector: 'app-tip-overview',
  templateUrl: './tip-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TipOverviewComponent {
  private store = inject(Store);

  tips$ = this.store.selectSignal(fromstore.getOrderedTips);
}

<section class="bg-space-gray-200 section-inset-b">
  <app-header
    class="tip_header"
    [title]="'app-tips-overzicht-titel' | fromDictionary"
    [text]="'app-tips-intro' | fromDictionary"
    [imageAnchor]="'topright'"
    [hideImageMobile]="true"
  ></app-header>

  <main class="faqs__main section-inset-y bg-space-gray-200">
    <div class="container mt-0 mt-md-3">
      <ul class="row">
        @for (tip of tips$(); track tip.id) {
          <li class="col-12 col-md-4 mb-3 mb-md-4">
            <app-tip-card [tip]="tip"></app-tip-card>
          </li>
        }
      </ul>

      <div class="text-max-width-xs" *ngIf="!tips$()?.length">
        <p class="mb-3">{{ 'app-verhalen-leeg' | fromDictionary }}</p>
        <a class="button button--primary" href="https://rova.nl/inwoners/verhalen">Verhalen op rova.nl</a>
      </div>
    </div>
  </main>
</section>

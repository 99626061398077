import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import * as fromStore from '../../store';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { ImageModule } from '@teamfoster/sdk/image';
import { CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';

import * as fromCookies from '@teamfoster/sdk/cookie-ngrx';
import { ContentPage } from 'src/app/content/models';
import { ContentPageHeaderComponent } from '../../../content/components/content-page-header/content-page-header.component';

@Component({
  selector: 'app-tip-detail',
  standalone: true,
  templateUrl: './tip-detail.component.html',
  styleUrl: './tip-detail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ImageModule, CookieNgrxModule, ContentPageHeaderComponent],
})
export class TipDetailComponent {
  private store = inject(Store);

  tip$ = this.store.selectSignal(fromStore.getSelectedTip);
  cookiePreferences$ = this.store.selectSignal(fromCookies.getCookiePreferences);

  asPage$ = computed(() => this.tip$() as unknown as ContentPage);
}

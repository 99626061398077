<article class="content-page pt-topbar">
  <!-- [imageUrl]="page.image.url" -->
  <app-content-page-header [backRoute]="['/tips']" [page]="asPage$()"></app-content-page-header>

  <main class="container container-size-xs section-inset-y content-page__main">
    <div
      class="content-page__markdown markdown-content"
      [innerHTML]="tip$().text || '' | resizeHtmlImages | stripEmbed: cookiePreferences$() || undefined"
    ></div>
  </main>
</article>
